// Variables
@import 'variables';


/***
========================================================================================================================
Font Awesome
Version 4.7.0
========================================================================================================================
 */
//@import 'sass-vendor/font-awesome-4.7.0/scss/font-awesome.scss';


/***
========================================================================================================================
Bootstrap
https://github.com/twbs/bootstrap
========================================================================================================================
 */
@import '~bootstrap/scss/bootstrap';
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";
//@import "~bootstrap/scss/root";
//@import "~bootstrap/scss/reboot";
//@import "~bootstrap/scss/type";
//@import "~bootstrap/scss/images";
//@import "~bootstrap/scss/code";
//@import "~bootstrap/scss/grid";
//@import "~bootstrap/scss/tables";
//@import "~bootstrap/scss/forms";
//@import "~bootstrap/scss/buttons";
//@import "~bootstrap/scss/transitions";
//@import "~bootstrap/scss/dropdown";
////@import "button-group";
////@import "input-group";
//@import "~bootstrap/scss/custom-forms";
//@import "~bootstrap/scss/nav";
//@import "~bootstrap/scss/navbar";
//@import "~bootstrap/scss/card";
//@import "~bootstrap/scss/breadcrumb";
////@import "pagination";
////@import "badge";
////@import "jumbotron";
//@import "~bootstrap/scss/alert";
////@import "progress";
//@import "~bootstrap/scss/media";
////@import "list-group";
//@import "~bootstrap/scss/close";
////@import "toasts";
//@import "~bootstrap/scss/modal";
////@import "tooltip";
////@import "popover";
////@import "carousel";
////@import "spinners";
//@import "~bootstrap/scss/utilities";

// slick-carousel
@import "./sass-vendor/_slick.scss";
@import "./sass-vendor/magnific-popup";



