/**************************************************/
/****************RESSET STYLE***********************/
/**************************************************/
a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center,
cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4,
h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre,
q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr,
tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;

  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after, blockquote:before, q:after, q:before {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}


/*************************************************************************/
/*****************FIX BOOTSTRAP DEFAULT CONTACINER***********************/
/*************************************************************************/
.container {
  max-width: 1300px;
}
.container-small{
  max-width: 1110px;
}


// Body
$body-bg: #fff;

$fa-font-path: "../webfonts";

// Typography
$font-family-sans-serif: 'Roboto', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.5;

// Colors
//$blue: #005791;
//$indigo: #6574cd;
//$purple: #9561e2;
//$pink: #f66d9b;
//$red: #e3342f;
//$orange: #ee653b;
//$yellow: #ffed4a;
//$green: #38c172;
//$teal: #4dc0b5;
//$cyan: #6cb2eb;

/***
====================================================================
	Fonts
====================================================================
 ***/
//'Open Sans', sans-serif
$font_primary: 'Source Sans Pro', sans-serif;
$font_secondary: 'Source Sans Pro', sans-serif;

/***
====================================================================
	Colors
====================================================================
 ***/

$blue_c: #5EACDD;
$red_c: #F51554;
$text_c: #2c3644;
$text_c_light: #828283;
$dark_color: #888;

$gray_bg: #f6f6f6;
$yellow_c: #FFB100;
$orange_c: #fb9d5b;

$primary: $blue_c;
$secondary: $orange_c;

$green_c: #159548;

$light_bg_c: #e8edf5;
//$success:       $green !default;
//$info:          $cyan !default;
//$warning:       $yellow !default;
//$danger:        $red !default;
//$light:         $gray-100 !default;
//$dark:          $gray-800 !default;

/***
====================================================================
	transition
====================================================================
 ***/
$blog_transition: all .3s ease-out;
/***
====================================================================
	clearfix
====================================================================
 ***/
%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

/*
--------------------------------------------------------------------
We can then extend the .clearfix class wherever necessary.
--------------------------------------------------------------------
.wrap {
	...
	@extend .clearfix;
}
.main-header {
	...
	@extend .clearfix;
}*/
/***
====================================================================
	Spacer
====================================================================
 ***/
@for $i from 1 through 10 {
  .spacer#{$i}0 {
    height: #{$i}0px;
  }
}

/*
.spacer50 --> height: 50px;
 */

/***
====================================================================
	media queries
====================================================================
 ***/
$min400: 400px;
$max400: max-width 399px;
$min480: 480px;
$max480: max-width 479px;
$min576: 576px;
$max576: max-width 575px;
$min768: 768px;
$max768: max-width 767px;
$min992: 992px;
$max992: max-width 991px;
$min1024: 1024px;
$max1024: max-width 1023px;
$min1200: 1200px;
$max1200: max-width 1199px;
$min1600: 1600px;
$max1600: max-width 1599px;
/*
media query example

@include breakpoint($min768) {
		position: fixed;
	}
*/

/***
====================================================================
	Mixins for Rem Font Sizing
====================================================================
 ***/

//@mixin font-size($sizeValue: 16) {
//    font-size: $sizeValue  + px;
//    font-size: ($sizeValue / 10) + rem;
//}

/*
@include font-size(16); -- 16px 1.6rem
*/

/***
====================================================================
    transition
====================================================================
 ***/
$transition_main_all: all .15s ease-in-out;

/***
====================================================================
    Shadows
====================================================================
 ***/
$box_shadow_1: 2px 2px 11px 0 rgba(0, 0, 0, 0.1);
$box_shadow_2: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);

/***
====================================================================
	Bootstrap them overrides
====================================================================
 ***/
//$enable-rounded: false !default;
$btn-border-radius: 3px;
$input-border-radius: 3px;
.form-control {
  border-radius: 2px;
}

//$border-radius-base:        18px ;
$theme-colors: (
        "primary": $blue_c,
        "secondary": $orange_c,
        "danger": #e5534c,
        "dark_primary":$dark_color,
);
//$font-weight-normal: 300;
//$font-weight-bold: 400;
::selection {
  background: $yellow_c;
  color: #ffffff;
}

::-moz-selection {
  background: $yellow_c;
  color: #ffffff;
}
